import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/community.tsx";
import loadable from '@loadable/component';
export const pageTitle = "Contributor manual";
export const ContributorList = loadable.lib(() => import('../../../gen-src/contributors.json'));
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  ContributorList,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "contributor-manual",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#contributor-manual",
        "aria-label": "contributor manual permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Contributor manual`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#you-are-the-contributor-"
        }}>{`You are the contributor 🚀`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#were-all-ears-"
        }}>{`We're all ears 👂`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#watch-our-community-in-action-"
        }}>{`Watch our community in action 🎬`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#sending-a-pull-request-"
        }}>{`Sending a pull request? 😍`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#be-respectful-to-others-"
        }}>{`Be respectful to others 🙇`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hall-of-fame-%EF%B8%8F"
        }}>{`Hall of fame 🏛️`}</a></li>
    </ul>
    <h2 {...{
      "id": "you-are-the-contributor-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#you-are-the-contributor-",
        "aria-label": "you are the contributor  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`You are the contributor 🚀`}</h2>
    <p>{`First of all, thank you so much for taking your time to visit Armeria project.
Here, everything you do or even your mere presence is a contribution in a broad sense.
We strongly believe your contribution will enrich our community and create
a virtuous cycle that propels the project into something great.`}</p>
    <h2 {...{
      "id": "were-all-ears-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#were-all-ears-",
        "aria-label": "were all ears  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We're all ears 👂`}</h2>
    <ul>
      <li parentName="ul">{`Join `}<a parentName="li" {...{
          "href": "/s/discord"
        }}>{`our Discord server`}</a>{` to chat with us.`}</li>
      <li parentName="ul">{`Ask a question or start a discussion at `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/discussions"
        }}>{`the discussion forum`}</a>{`.`}</li>
      <li parentName="ul">{`File an issue in `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues?q=is%3Aissue+is%3Aopen+sort%3Aupdated-desc"
        }}>{`the issue tracker`}</a>{`
to report a bug or suggest an idea.`}
        <ul parentName="li">
          <li parentName="ul">{`In case of a security issue, use `}<a parentName="li" {...{
              "href": "https://bugbounty.linecorp.com/en/"
            }}>{`LINE Security Bug Bounty Program`}</a>{`
or `}<a parentName="li" {...{
              "href": "mailto:dl_oss_dev@linecorp.com"
            }}>{`contact us`}</a>{` directly.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "watch-our-community-in-action-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#watch-our-community-in-action-",
        "aria-label": "watch our community in action  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Watch our community in action 🎬`}</h2>
    <ul>
      <li parentName="ul">{`Check out `}<a parentName="li" {...{
          "href": "/community/articles"
        }}>{`the articles and presentations`}</a>{` by our community.`}</li>
      <li parentName="ul">{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}
        <div style={{
          "maxWidth": "320px",
          "marginTop": "0.5rem"
        }}>
  <Mailchimp mdxType="Mailchimp" />
        </div>
      </li>
    </ul>
    <h2 {...{
      "id": "sending-a-pull-request-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sending-a-pull-request-",
        "aria-label": "sending a pull request  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sending a pull request? 😍`}</h2>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "/community/developer-guide"
      }}>{`the developer guide`}</a>{` to learn how to build, develop and send an awesome pull request.`}</p>
    <h2 {...{
      "id": "be-respectful-to-others-",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#be-respectful-to-others-",
        "aria-label": "be respectful to others  permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Be respectful to others 🙇`}</h2>
    <p>{`We expect contributors to follow `}<a parentName="p" {...{
        "href": "/community/code-of-conduct"
      }}>{`our code of conduct`}</a>{`.`}</p>
    <h2 {...{
      "id": "hall-of-fame-️",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hall-of-fame-%EF%B8%8F",
        "aria-label": "hall of fame ️ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hall of fame 🏛️`}</h2>
    <ContributorList mdxType="ContributorList">
  {({
        default: contributors
      }) => <ThankYou usernames={Object.keys(contributors)} avatarUrls={contributors} message={() => <p>See <a href="https://github.com/line/armeria/contributors">the complete list of our contributors</a>.</p>} href={username => `https://github.com/line/armeria/commits?author=${username}`} mdxType="ThankYou" />}
    </ContributorList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      